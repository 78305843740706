import React from "react";
import { graphql} from 'gatsby';
import Layout from "../components/Layout";
import ReactMarkdown from 'react-markdown';
import SEO from "../components/SEO";
export default ({ data }) => {
    const {content, title, date, thumbnail} = data.blog;
    return (
        <Layout>
            <section className="section-container top" id="blog-page">
                <SEO title={title}/>
                <article className="content">
                    <h3 className="header">{title}</h3>
                    <p className="text-light">{date}</p>
                    <img alt="article" className="image" src={thumbnail.childImageSharp.fluid.src}></img>
                    <div className="markdown">
                        <ReactMarkdown source={content}/>
                    </div>
                </article>
            </section>
        </Layout>
    )
}
export const query = graphql`
    query GetSingleBlog($slug: String){
        blog: strapiBlogs(slug: {eq: $slug}){
            content
            title
            date(formatString: "MMM D, YYYY")
            thumbnail {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }   
    }
}
`